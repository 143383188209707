<template>
  <v-card flat tile height="100%">
    <template v-if="data">
      <v-toolbar flat dense class="sticky_solutionSelector">
        <v-toolbar-title>
          {{ data.Name }}
          {{
            data.Properties.Number ? " (" + data.Properties.Number + ")" : ""
          }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isFirelog"
          class="mr-2"
          v-on:click="useInMarker()"
          small
          depressed
          outlined
          color="secondary"
        >
          {{ $t("usethisinmarker") }}
        </v-btn>
        <v-btn outlined small depressed v-on:click="favorite()">
          <v-icon small v-if="data.IsFavorite"> mdi-star </v-icon>
          <v-icon small v-else> mdi-star-outline </v-icon>
        </v-btn>
        <v-btn outlined small depressed v-on:click="downloadPdf()">
          <v-icon small>mdi-download</v-icon>
        </v-btn>
        <v-btn
          outlined
          small
          depressed
          v-on:click="backToSolutionList"
          v-if="selectorSteps && useProps"
        >
          <v-icon small>mdi-arrow-left</v-icon>
        </v-btn>
      </v-toolbar>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="blue darken-2"
      ></v-progress-linear>
      <!-- Body -->
      <!-- <div class="main_wrapper mt-2"> -->
      <v-container>
        <v-row>
          <v-col>
            <breadCrumb
              :selectedTaxonomies="breadCrumbSteps"
              :isDetailView="true"
              @prevStep="prevStep"
              :selectedAutoSkipped="useProps ? selectedAutoSkipped : null"
            />
          </v-col>
        </v-row>
        <v-row>
          <!-- Linkerkant -->
          <!-- <div class="main_taxonomies"> -->
          <v-col cols="12" sm="6" class="main_taxonomies">
            <div>
              <template>
                <div class="option wrapper_content_taxonomy">
                  <!-- Constructie element -->
                  <div class="option subtitle-1 font-weight-bold">
                    <div
                      class="row row--dense"
                      v-if="data.Properties.Taxonomy2Name"
                    >
                      <div class="col">
                        {{ data.Properties.Taxonomy2Title }}:
                      </div>
                      <div class="col">
                        {{ data.Properties.Taxonomy2Name }}
                      </div>
                    </div>
                    <v-row dense>
                      <v-col class="caption font-weight-medium">
                        {{ $t("thickness") }} (mm)
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.Taxonomy9Name }}
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider
                    v-if="
                      data.Properties.Taxonomy2Name ||
                      data.Properties.Taxonomy9Name
                    "
                  ></v-divider>

                  <!--div class="option subtitle-1 font-weight-bold">
                  <div class="row row--dense" v-if="data.Properties.Taxonomy9Name">
                    <div class="col">{{ data.Properties.Taxonomy9Title }}:</div>
                    <div class="col">{{ data.Properties.Taxonomy9Name }}</div>
                  </div>
                </div>
                <v-divider></v-divider-->

                  <!-- Doorvoering -->
                  <div class="option subtitle-1 font-weight-bold">
                    <div
                      class="row row--dense"
                      v-if="data.Properties.Taxonomy3Name"
                    >
                      <div class="col">
                        {{ data.Properties.Taxonomy3Title }}:
                      </div>
                      <div class="col">
                        {{ data.Properties.Taxonomy3Name }}
                      </div>
                    </div>
                    <v-row dense v-if="data.Properties.Taxonomy4Name">
                      <v-col class="caption font-weight-medium">
                        {{ data.Properties.Taxonomy4Title }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.Taxonomy4Name }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.Taxonomy1Name">
                      <!--v-col class="caption font-weight-medium">{{ data.Properties.Taxonomy1Title }}</v-col-->
                      <v-col class="caption font-weight-medium">
                        {{ $t("diameter") }} (mm)
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.Taxonomy1Name }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.WandDikte">
                      <v-col class="caption font-weight-medium">
                        {{ $t("wallThickness") }} (mm)
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.WandDikte }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.Capping">
                      <v-col class="caption font-weight-medium">
                        {{ $t("capping") }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.Capping }}
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider
                    v-if="
                      data.Properties.Taxonomy3 ||
                      data.Properties.Taxonomy4 ||
                      data.Properties.Taxonomy1 ||
                      data.Properties.WandDikte ||
                      data.Properties.Capping
                    "
                  ></v-divider>

                  <!-- Type Isolatie -->
                  <div class="option subtitle-1 font-weight-bold">
                    <div
                      class="row row--dense"
                      v-if="data.Properties.Taxonomy5Name"
                    >
                      <div class="col">
                        {{ data.Properties.Taxonomy5Title }}:
                      </div>
                      <div class="col">
                        {{ data.Properties.Taxonomy5Name }}
                      </div>
                    </div>
                    <v-row dense v-if="data.Properties.Taxonomy8Name">
                      <v-col class="caption font-weight-medium">
                        {{ $t("brand") }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.Taxonomy8Name }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.IsolatieVorm">
                      <v-col class="caption font-weight-medium">
                        {{ $t("insulationForm") }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.IsolatieVorm }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.IsolatieLengte">
                      <v-col class="caption font-weight-medium">
                        {{ $t("insulationLength") }} (mm)
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.IsolatieLengte }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.IsolatieDikte">
                      <v-col class="caption font-weight-medium">
                        {{ $t("insulationThickness") }} (mm)
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.IsolatieDikte }}
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider
                    v-if="
                      data.Properties.Taxonomy5Name ||
                      data.Properties.Taxonomy8Name ||
                      data.Properties.IsolatieVorm ||
                      data.Properties.IsolatieLengte ||
                      data.Properties.IsolatieDikte
                    "
                  ></v-divider>

                  <!-- Product Oplossing -->
                  <div class="option subtitle-1 font-weight-bold">
                    <div class="row row--dense">
                      <div class="col">{{ $t("productSolution") }}:</div>
                      <div class="col">{{ data.Name }}</div>
                    </div>
                    <v-row dense v-if="data.Properties.ProductOplossingen2">
                      <v-col class="caption font-weight-medium">
                        {{ $t("alongWith") }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.ProductOplossingen2 }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.Taxonomy6Name">
                      <v-col class="caption font-weight-medium">
                        {{ data.Properties.Taxonomy6Title }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.Taxonomy6Name }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.Taxonomy7Name">
                      <v-col class="caption font-weight-medium">
                        {{ $t("situation") }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.Taxonomy7Name }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.AanbrengenAan">
                      <v-col class="caption font-weight-medium">
                        {{ $t("apply") }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.AanbrengenAan }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.AantalLagen">
                      <v-col class="caption font-weight-medium">
                        {{ $t("nrOfLayers") }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.AantalLagen }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.MaximaleOpening">
                      <v-col class="caption font-weight-medium">
                        {{ $t("maxOpening") }} (mm)
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.MaximaleOpening }}
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider></v-divider>

                  <!-- Maatvoeringen -->
                  <div class="option subtitle-1 font-weight-bold">
                    {{ $t("dimensions") }}:
                    <v-row dense v-if="data.Properties.AfstandTotRanden">
                      <v-col class="caption font-weight-medium">
                        {{ $t("distanceTo") }} {{ $t("edge") }} (mm)
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.AfstandTotRanden }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.AfstandTotAnderen">
                      <v-col class="caption font-weight-medium">
                        {{ $t("distanceTo") }} {{ $t("others") }} (mm)
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.AfstandTotAnderen }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.AfstandTotZelfdeType">
                      <v-col class="caption font-weight-medium">
                        {{ $t("distanceTo") }} {{ $t("sameType") }} (mm)
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.AfstandTotZelfdeType }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.MaximaleHoek">
                      <v-col class="caption font-weight-medium">
                        {{ $t("minMaxAngle") }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.MaximaleHoek }}
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider
                    v-if="
                      data.Properties.AfstandTotRanden ||
                      data.Properties.AfstandTotAnderen ||
                      data.Properties.AfstandTotZelfdeType ||
                      data.Properties.MaximaleHoek
                    "
                  ></v-divider>

                  <!-- Naad -->
                  <div class="option subtitle-1 font-weight-bold">
                    {{ $t("juncture") }}:
                    <v-row dense>
                      <v-col class="caption font-weight-medium">
                        {{ $t("material") }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.MateriaalNaad }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.BreedteNaad">
                      <v-col class="caption font-weight-medium">
                        {{ $t("width") }} (mm)
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.BreedteNaad }}
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider
                    v-if="
                      data.Properties.MateriaalNaad ||
                      data.Properties.BreedteNaad
                    "
                  ></v-divider>

                  <!-- Bevestigen -->
                  <div class="option subtitle-1 font-weight-bold">
                    {{ $t("secure") }}:
                    <v-row dense>
                      <v-col class="caption font-weight-medium">
                        {{ $t("material") }}
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.Bevestiging }}
                      </v-col>
                    </v-row>
                    <v-row dense v-if="data.Properties.BeugelAfstand">
                      <v-col class="caption font-weight-medium">
                        {{ $t("bracketDistance") }} (mm)
                      </v-col>
                      <v-col class="caption">
                        {{ data.Properties.BeugelAfstand }}
                      </v-col>
                    </v-row>
                  </div>
                  <v-divider
                    v-if="
                      data.Properties.Bevestiging ||
                      data.Properties.BeugelAfstand
                    "
                  ></v-divider>
                  <!-- Disclaimer -->
                  <div v-if="data.Disclaimer && !data.Properties.ClassificatieRapport">
                    <div class="option subtitle-1 font-weight-bold">
                      {{ $t("disclaimer") }}:
                    </div>
                    <v-row>
                      <v-col>
                        <div
                          v-html="data.Disclaimer"
                          class="option caption"
                        ></div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
                <v-divider v-if="data.Disclaimer">></v-divider>
              </template>
            </div>
          </v-col>
          <!-- Rechterkant -->
          <v-col cols="12" sm="6">
            <!-- <div v-for="(selectedTax,i) in data.SelectedTaxonomies" :key="i">
            <template v-if="selectedTax">
              <div class="option wrapper_content_taxonomy">
                <div class="option">
                  <v-img height="70" width="70" v-if="selectedTax.Image" :src="selectedTax.Image">
                  </v-img>
                </div>
                <div class="subtitle-1 option">{{`${$t('step')} ${i + 1}: `}}{{selectedTax.Title}}
                  <div class="caption font-weight-medium">{{selectedTax.Name}}</div>
                  <div class="caption" v-html="selectedTax.Description"></div>
                </div>
              </div>
              <v-divider v-if="i != data.SelectedTaxonomies.length -1"></v-divider>
            </template>
          </div> -->
            <!-- </div> -->
            <!-- <div> -->
            <!-- Image -->
            <div class="option main_images">
              <template v-if="data.Images && data.Images.length > 0">
                <!-- <img :src="data.Images[0]" v-bind:style="{ width: '100%', height: '100%' }"> -->
                <zoomPicture :src="data.Images[0]"></zoomPicture>
                <!-- <v-img :src="data.Images[0]" contain height="360px"></v-img> -->
              </template>
            </div>
            <v-divider></v-divider>

            <!-- Norm -->
            <div class="option subtitle-1 font-weight-bold">
              <div class="row row--dense">
                <div class="col">{{ $t("norm") }}:</div>
                <div class="col">{{ data.Properties.Norm }}</div>
              </div>
              <v-row dense v-if="data.Properties.ETAOfRapport">
                <v-col class="caption font-weight-medium" v-if="!data.Properties.ClassificatieRapport">
                  ETA
                </v-col>
                <v-col class="caption font-weight-medium" v-if="data.Properties.ClassificatieRapport">
                  Rapport
                </v-col>
                <v-col class="caption">
                  {{ data.Properties.ETAOfRapport }}
                </v-col>
              </v-row>
            </div>

            <!-- Download -->
            <v-divider v-if="data.Attachments.length > 0"></v-divider>
            <div
              class="option main_attachments"
              v-if="data.Attachments.length > 0"
            >
              <div class="option subtitle-1 font-weight-bold">
                {{ $t("download") }}:
              </div>
              <!-- <div v-for="(attachment, i) in data.Attachments" :key="i"> -->
              <!-- <div class="option wrapper_content_attachment"> -->
              <!-- <div class="subtitle-2 option"> -->
              <a
                v-for="(attachment, i) in data.Attachments"
                :key="i"
                class="subtitle-2 option"
                target="_blank"
                :href="attachment.Link"
              >
                {{ attachment.Category }}
              </a>
              <!-- <div class="caption font-weight-medium">{{selectedTax.Name}}</div>
                <div class="caption" v-html="selectedTax.Description"></div>-->
              <!-- </div> -->
              <!-- <v-img class="option" :src="tax.Image" aspect-ratio="1" height="50"></ -->
              <!-- </div> -->
              <!-- <v-divider v-if="i != data.Attachments.length - 1"></v-divider> -->
              <!-- </div> -->
            </div>

            <!-- Products -->
            <v-divider v-if="data.Products.length > 0"></v-divider>
            <div class="option main_products" v-if="data.Products.length > 0">
              <div class="option subtitle-1 font-weight-bold">
                {{ $t("products") }}:
              </div>
              <div class="scroll-container">
                <div
                  v-for="(product, i) in data.Products"
                  :key="'p-' + i"
                  class="text-truncate"
                >
                  <v-layout class="pa-2" column>
                    <a target="_blank" :href="product.Link">
                      <v-img
                        gradient="to bottom, rgba(0,0,0,.1), rgba(255,255,255,.5)"
                        aspect-ratio="1"
                        width="142"
                        :src="product.Image"
                        dark
                      >
                        <v-card-actions
                          class="fill-height align-end gradient text-truncate"
                        >
                          <span
                            class="text-truncate d-inline-block"
                            style="color: black"
                          >
                            {{ product.Name }}
                          </span>
                        </v-card-actions>
                        <template v-slot:placeholder>
                          <v-layout
                            fill-height
                            align-center
                            justify-center
                            ma-0
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-layout>
                        </template>
                      </v-img>
                    </a>
                  </v-layout>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-container class="main_wrapper mt-5" v-else>
      <div v-if="error" class="title mt-6 text-center overline mx-auto">
        {{ $t("error") }}
      </div>
      <template v-else>
        <v-skeleton-loader type="article"></v-skeleton-loader>
        <v-skeleton-loader type="image"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
        <v-skeleton-loader type="list-item-three-line"></v-skeleton-loader>
      </template>
    </v-container>
  </v-card>
</template>

<script>
import ajax from "@/services/service.oryx.js";
import jwtdecode from "jwt-decode";
import zoomPicture from "@/components/SolutionZoomPicture";
import breadCrumb from "@/components/BreadCrumb";
import { mapState } from "vuex";

export default {
  name: "DetailPage",
  components: {
    zoomPicture,
    breadCrumb,
  },
  props: {
    // id: String,
    // taxId: String,
    isFirelog: Boolean,
    selectorSteps: {
      type: Object,
      required: false,
    },
    // brand: Object,
    // product: Object,
    // productType: Object,
    selectedStepsFromSelector: Array,
    selectedStepIdsFromSelector: Array,
    selectedAutoSkipped: Array,
  },
  data() {
    return {
      useProps: true,
      data: null,
      loading: false,
      error: false,
    };
  },
  computed: {
    breadCrumbSteps() {
      return this.useProps
        ? this.selectedStepsFromSelector
        : this.data.SelectedTaxonomies;
    },
    brand() {
      return this.choices.brand;
    },
    product() {
      return this.choices.product;
    },
    productType() {
      return this.choices.productType;
    },
    id() {
      return this.selectedSolution.id;
    },
    taxId() {
      return this.selectedSolution.taxId;
    },
    ...mapState({
      choices: "solutionSelectorChoices",
      selectedSolution: "selectedSolution",
    }),
  },
  created() {
    if (!this.id) {
      //get or save id to localState so refresh is possible?
      this.$router.push({ name: "Home" });
    } else {
      this.getSelectedSolution();
    }
  },
  methods: {
    backToSolutionList() {
      //send message to parent to clear selected solution
      // this.$emit("back");
      this.$router.push({
        name: "Selector",
        params: {
          dialog: null,
          isFirelog: this.isFirelog,
          comeBackToSelector: this.useProps
            ? JSON.parse(JSON.stringify(this.selectorSteps))
            : null,
        },
      });
    },
    useInMarker() {
      let taxonomies = this.data.SelectedTaxonomies.filter(
        (x) => x !== null
      ).map((x) => x.Id);
      this.$emit("useInMarkerFromDetails", {
        solution: this.data,
        taxonomies: taxonomies,
      });
    },
    getSelectedSolution() {
      this.loading = true;
      let user = jwtdecode(localStorage.getItem("auth"));
      let params = {
        userId: user.id,
        solutionId: this.id,
        tax263415Id: this.taxId,
        companyId: 3, //jwtdecode(localStorage.getItem("auth")).companyId,
        lc: "NL", //localStorage.getItem("language"),
      };
      if (this.taxId == "00000000-0000-0000-0000-000000000000") {
        params.tax263415Id = null;
      }

      ajax
        .getSelectedSolution(params)
        .then((res) => {
          this.data = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.error = true;
        });
    },

    favorite() {
      const solution = this.data;
      let user = jwtdecode(localStorage.getItem("auth"));
      // console.log(user);
      const params = {
        userId: user.id,
        Taxonomy26734158Id: this.taxId, //solution.Taxonomy296734158s[0].Id,
      };
      // console.log("Params: ", params);
      ajax.createFavorite(params).then((res) => {
        // console.log(res);
        if (res.data == "Deleted") {
          this.data.IsFavorite = false;
        } else if (res.data == "Created") {
          this.data.IsFavorite = true;
        }
      });
    },

    prevStep(i) {
      let fromSelector = this.useProps; //this.selectedStepsFromSelector ? true : false;
      let selectedTaxonomyIdsFromDetailView = [];
      let selectedAutoSkippedFromDetailView = [];
      if (fromSelector) {
        selectedTaxonomyIdsFromDetailView =
          this.selectedStepIdsFromSelector.slice(0, i);
        selectedAutoSkippedFromDetailView = this.selectedAutoSkipped.slice(
          0,
          i
        );
      } else {
        let selectedTaxonomiesFromDetailView =
          this.data.SelectedTaxonomies.slice(0, i);
        selectedTaxonomiesFromDetailView.forEach((element) => {
          selectedTaxonomyIdsFromDetailView.push(element.Id);
        });
      }
      // console.log(selectedTaxonomyIdsFromDetailView);

      this.$router.push({
        name: "Selector",
        params: {
          selectedTaxonomyIdsFromDetailView,
          selectedAutoSkippedFromDetailView,
          // productType: this.productType,
          // brand: this.brand,
          // product: this.product,
        },
      });
    },

    downloadPdf() {
      let user = jwtdecode(localStorage.getItem("auth"));
      let params = {
        userId: user.id,
        solutionId: this.id,
        tax263415Id: this.taxId,
        companyId: 3, //jwtdecode(localStorage.getItem("auth")).companyId,
        lc: "NL", //localStorage.getItem("language"),
      };
      if (this.taxId == "00000000-0000-0000-0000-000000000000") {
        params.tax263415Id = null;
      }

      ajax
        .getSelectedSolutionPdf(params)
        .then((res) => {
          var trim = res.data.replace(/(^")|("$)/g, "");

          const url = window.URL.createObjectURL(
            new Blob([Buffer.from(trim, "base64")])
          );
          const link = document.createElement("a");
          link.href = url;
          let date = new Date();
          const regex = /[T\-:]/gi;
          let dateString = date.toISOString();
          let pdfName =
            this.data.Properties.Number +
            "_" +
            dateString.slice(0, dateString.indexOf(".")).replaceAll(regex, "") +
            ".pdf";
          link.setAttribute("download", pdfName);
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.error = true;
        });

      // this.loading = true;
      // this.$http
      //   .downloadLogbook(project.id, null)
      //   .then(res => {
      //     const url = window.URL.createObjectURL(new Blob([res.data]));
      //     const link = document.createElement("a");
      //     link.href = url;
      //     let date = new Date();
      //     link.setAttribute(
      //       "download",
      //       `${project.name}_${date.toLocaleDateString()}.zip`
      //     );
      //     document.body.appendChild(link);
      //     link.click();
      //     this.loading = false;
      //   })
      //   .catch(err => {
      //     console.log(err);
      //     this.$noty.error(this.$t("error"));
      //     this.loading = false;
      //   });
    },
  },
  watch: {
    // id(n, o) {
    //   this.loading = true;
    //   this.selectedStepsFromSelector = [];
    //   this.selectedStepIdsFromSelector = [];
    //   this.selectedAutoSkipped = [];
    //   // console.log("new selected solution", n);
    //   this.getSelectedSolution();
    // },
    taxId(n, o) {
      this.loading = true;
      this.useProps = false;
      // console.log("new selected solution", n);
      this.getSelectedSolution();
    },
  },
};
</script>
<style scoped>
.main_wrapper {
  --auto-grid-min-size: 25rem;
  display: grid;
  grid-gap: 1em;

  grid-template-columns: minmax(var(--auto-grid-min-size), 3fr 1fr);
  grid-template-areas:
    "taxonomies images"
    "application products"
    "attachments description";
  grid-auto-flow: dense; /* NEW */
}
@media (min-width: 900px) {
  .main_wrapper {
    grid-template-columns: 50% 50%;
    grid-auto-flow: dense; /* NEW */
  }
}

@media (max-width: 768px) {
  .main_wrapper {
    --auto-grid-min-size: 25rem;
    display: grid;
    grid-gap: 0.2em;

    grid-template-areas:
      "images"
      "application"
      "taxonomies"
      "products"
      "attachments"
      "description";
    grid-auto-flow: dense; /* NEW */
  }
}

.main_wrapper > div {
  padding: 0.2em;
}
.wrapper_content_taxonomy {
  display: grid;
  grid-template-columns: 4fr;
  /* grid-template-columns: 1fr 3fr; */
}
.wrapper_content_attachment {
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.option {
  border-radius: 5px;
  padding: 5px;
  height: auto;
}
.scroll-container {
  display: flex;
  overflow: auto;
  flex: none;
  scroll-snap-type: x mandatory;
}
.main_products {
  overflow: auto;
  grid-area: products;
}
.main_application {
  grid-area: application;
}

.main_attachments {
  grid-area: attachments;
}
.main_taxonomies {
  grid-area: taxonomies;
}
.main_images {
  overflow: hidden;
  grid-area: images;
}
.main_description {
  grid-area: description;
}
.scroll-container > div {
  text-align: center;
  scroll-snap-align: center;
  flex: none;
}
.sticky_solutionSelector {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.custom-border {
  border: 1.5px;
  border-style: solid;
}
.border-green-light {
  border-color: #00a0e4;
}
.border-blue-light {
  border-color: greenyellow;
}
.border-green {
  border-color: green;
}
.border-blue {
  border-color: blue;
}
.border-grey {
  border-color: grey;
}
.border-yellow {
  border-color: yellow;
}
.border-brown-light {
  border-color: burlywood;
}
.border-brown {
  border-color: brown;
}
</style>
